var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"font-title margin"},[_vm._v("个人列表")]),_c('p',{staticClass:"margin"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.seller}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 新增自然人")],1)],1),_c('p',{staticClass:"margin"},[_c('a-input-search',{staticStyle:{"width":"500px"},attrs:{"enter-button":"","placeholder":"请输入姓名、证件号模糊查询"},on:{"search":function($event){_vm.form.pageNum=1;_vm.getList()}},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1),_c('a-table',{attrs:{"data-source":_vm.data,"columns":_vm.naturalColumns,"loading":_vm.loading,"rowKey":function (record,index){return index},"size":"middle","pagination":{
    size: 'default',
    total: _vm.form.total,
    current: _vm.form.pageNum,
    pageSize: _vm.form.pageSize,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50', '100'],
    showTotal: function (total) { return ("共有 " + (_vm.form.total) + " 条数据"); },
    showQuickJumper: true,
    onShowSizeChange: function (current, pageSize) {_vm.form.pageSize = pageSize; _vm.form.pageNum = 1},
    onChange: function (pageNum) { return _vm.form.pageNum = pageNum; } }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"legalName",fn:function(text,record){return [_c('router-link',{attrs:{"to":("/account-center/apply?id=" + (record.id))}},[_vm._v(_vm._s(text))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }