<template>
  <div>
    <p class="font-title margin">个人列表</p>

    <p class="margin"><a-button type="primary" @click="seller"><a-icon type="plus"></a-icon> 新增自然人</a-button></p>

    <p class="margin"><a-input-search v-model="form.search" @search="form.pageNum=1;getList()" style="width: 500px" enter-button placeholder="请输入姓名、证件号模糊查询"></a-input-search></p>

    <a-table :data-source="data" :columns="naturalColumns" :loading="loading" :rowKey="(record,index)=>{return index}" @change="handleTableChange" size="middle"
    :pagination="{
      size: 'default',
      total: form.total,
      current: form.pageNum,
      pageSize: form.pageSize,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '20', '50', '100'],
      showTotal: total => `共有 ${form.total} 条数据`,
      showQuickJumper: true,
      onShowSizeChange: (current, pageSize) =>{form.pageSize = pageSize; form.pageNum = 1},
      onChange: (pageNum) => form.pageNum = pageNum }"
    >
      <template slot-scope="text,record" slot="legalName">
        <router-link :to="`/account-center/apply?id=${record.id}`">{{text}}</router-link>
      </template>
    </a-table>
  </div>
</template>

<script>
const naturalColumns = [
  {
    title: '个人姓名',
    dataIndex: 'legalName',
    scopedSlots: { customRender: 'legalName' },
    width: 90,
    align: 'left',
  },
  {
    title: '身份证',
    dataIndex: 'idCard',
    align: 'left',
  },
  {
    title: '银行账号',
    dataIndex: 'bankAccount',
    align: 'left',
  },
  {
    title: '开户银行',
    dataIndex: 'openBankName',
    align: 'left',
  },
  {
    title: '银行预留号码',
    dataIndex: 'bankReservedMobile',
    align: 'left',
  },
  {
    title: '注册时间',
    dataIndex: 'createdTime',
    width: 170,
    align: 'left',
  },
]
import {naturalList} from '@/api/user'
export default {
    data() {
        return {
            naturalColumns,
            data: [],
            loading: false,
            form: {
              pageNum: 1,
              pageSize: 5,
              search: '',
              total: 0
            }
        }
    },
    mounted() {
      this.getList()
    },
    methods: {
      seller(){
        this.$router.push("/perfectinfo")
      },
      getList() {
        this.loading = true
        naturalList(this.form).then(res => {
          console.log(res)
          this.loading = false
          this.data = res.data.list
          this.form.total = res.data.total
        })
      },
      handleTableChange (e) {
        this.form.pageNum = e.current
        this.form.pageSize = e.pageSize
        this.getList()
    },
    }
}
</script>

<style lang="less" scoped>
.font-title {
  font-weight: 650;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  margin-bottom: 24px;
}
.margin {
    margin-bottom: 20px;
}
</style>